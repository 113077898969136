import React from "react"
import Grid from "@material-ui/core/Grid"
import slugify from "slugify"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Layout from "../components/layout"

const getSlug = (text) => slugify(text, {
  replacement: '-',
  lower: true,
  trim: true 
})

const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: 100
  },
  gridItem: {
    position: 'relative',
  },
  imageTitle: {
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
    left: theme.spacing(1.5),
    opacity: 0,
    color: theme.palette.common.white,
    cursor: "pointer",
    display: 'flex',
    position: 'absolute',
    backgroundColor: "hsl(0deg 0% 100% / 0%)",
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 250ms, background 250ms',
    "&:hover": {
      opacity: 1,
      backgroundColor: "hsl(0deg 0% 100% / 40%)",
    }
  }
}))

const PortfolioPage = ({ data }) => {
  const classes = useStyles()
  const { portfolios = [], seo, seoMetaTags } =  (data && data.datoCmsPortfolioPage) || {}

  return (
    <Layout logo="black">
      <Seo meta={seoMetaTags?.tags} title={seo?.title} description={seo?.description} />
      <Container maxWidth="lg">
        <Grid container spacing={3} className={classes.grid}>
          {portfolios.map(({ id, backgroundImage, portfolioName }) => {
            const alt = `Portfolio - ${portfolioName}`
            return (
              <Grid item lg={3} md={4} sm={6} xs={12} key={id} className={classes.gridItem}>
                <Link to={`/portfolio/${getSlug(portfolioName)}`}>
                  <GatsbyImage image={getImage(backgroundImage)} alt={alt} />
                  <Typography variant="h3" className={classes.imageTitle}>{portfolioName}</Typography>
                </Link>
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsPortfolioPage {
      title
      portfolios {
        id
        portfolioName
        backgroundImage {
          gatsbyImageData(
            aspectRatio: 1
            layout: FULL_WIDTH
            height: 600
            width: 600
            placeholder: BLURRED
          )
        }
        images {
          gatsbyImageData(
            layout: FULL_WIDTH
            height: 800
            width: 800
            placeholder: BLURRED
          )
        }
      }
      seoMetaTags {
        tags
      }
      seo {
        title
        description
      }
    }
  }
`

export default PortfolioPage
